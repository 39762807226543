<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'management-dashboard' }" class="root">
        Dashboard
      </router-link>
      <span class="child">/ Employees </span> </v-row
    ><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
              <template>
                <download-excel
                  :data="data_list"
                  :fields="all_"
                  worksheet="Employee Data"
                  name="Employee Data.xls"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        size="40"
                        left
                        v-bind="attrs"
                        v-on="on"
                        >mdi-file-excel</v-icon
                      >
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
            <template>
              <div class="text-center" v-if="circular">
                <v-progress-linear indeterminate height="25" color="#393e46">
                  <strong style="color:#FFF;">Loading...</strong>
                </v-progress-linear>
              </div>
            </template>
            <v-data-table
              :headers="headers"
              :items="data_list"
              :search="search"
            >
              <template v-slot:item.totalemp="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      color="gray"
                      v-bind="attrs"
                      v-on="on"
                      dark
                      @click="getdetails(item)"
                    >
                      {{ item.totalemp }}
                    </v-chip>
                  </template>
                  <span> View Details</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </template>
      </div>
    </section>
    <!-- /. section -->
    <!-- details -->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog_details"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="gray">
              <v-btn icon dark @click="dialog_details = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ org_name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="dialog_details = false">
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <br />
            <v-card-text>
              <v-card>
                <v-card-title>
                  <v-text-field
                    v-model="search_"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel
                      :data="details_list"
                      :fields="single_"
                      worksheet="Employee Data"
                      name="Employee Data.xls"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="green"
                            size="40"
                            left
                            v-bind="attrs"
                            v-on="on"
                            >mdi-file-excel</v-icon
                          >
                        </template>
                        <span>Import to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>
                <template>
                  <div class="text-center" v-if="circular">
                    <v-progress-linear
                      indeterminate
                      height="25"
                      color="#393e46"
                    >
                      <strong style="color:#FFF;">Loading...</strong>
                    </v-progress-linear>
                  </div>
                </template>
                <v-data-table
                  :headers="headers_"
                  :items="details_list"
                  :search="search_"
                >
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    circular: false,
    data_list: [],
    details_list: [],
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    search_: "",
    org_name: null,
    dialog_details: false,
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Orgnization", value: "orgName" },
      { text: "Number of Employees", value: "totalemp" },
    ],
    headers_: [
      { text: "Sr. No.", value: "srno" },
      { text: "Orgnization", value: "orgName" },
      { text: "Employee_Code", value: "emp_code" },
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Contact", value: "mobile_number" },
      { text: "Address", value: "address" },
      { text: "City", value: "city" },
      { text: "State", value: "state" },
      { text: "Country", value: "country" },
      { text: "Pin", value: "pin" },
    ],
    single_: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      Employee_Code: "emp_code",
      Name: "name",
      Email: "email",
      Contact: "mobile_number",
      Address: "address",
      City: "city",
      State: "state",
      Country: "country",
      Pin: "pin",
    },
    all_: {
      "Sr. No.": "srno",
      Orgnization: "orgName",
      "Number of Employee": "totalemp",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.circular=true
      axios
        .post("/Management/getAllOrgEmpData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.data_list = res.data.epmlist;
            this.circular=false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    getdetails(item) {
      const data = {
        orgid: item.orgid,
      };
      this.circular=true
      axios
        .post("/Management/getEmployeeDetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.details_list = res.data.empdetails;
            this.org_name = res.data.orgName;
            this.dialog_details = true;
            this.circular=false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
</style>
